import React, { FC, Fragment, useEffect, useState } from 'react'
import "./creditscoreconfiguration.scss"
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import IconComponent from '../../../../shared/components/IconComponent'
import TableComponent from '../../../../shared/components/TableComponent'
import { CreditScoreCriterions } from '../../../../models/CreditScoreCriterions/creditScoreCriterions.model'
import CreditScoreConfigurationForm from './CreditScoreConfigForm'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import { ConfigurationFormTypes } from '../../../../enums/configurationFormTypes'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import {DeleteOutlined} from '@ant-design/icons';
import Notification from '../../../../shared/components/Notification'
import { NotificationTypes } from '../../../../enums/notificationTypes'
import { DatasourceTypesDefinition } from '../../../../definitions/datasourceTypesDefinition'
import { DatasourceTypes } from '../../../../enums/datasourceTypes'
import refresh from "../../../../assets/images/recalculate.svg"
import add from "../../../../assets/images/add.svg"
import ApprovalThresholdForm from './ApprovalThresholdForm'
import CardComponent from '../../../../shared/components/CardComponent'
import { Col, Row } from 'antd'
import MetaService from '../../../../services/MetaService/meta.service'
import ModalComponent from "../../../../shared/components/ModalComponent";
import alertIcon from "../../../../assets/images/alert-for-refund-creation.svg";
import { mobileScreenWidth } from '../../../../enums/constants'

const CreditScoreConfiguration: FC = () => {
  const loading = false
  const [isCreditScoreConfigFormShown,setIsCreditScoreConfigFormShown] = useState(false)
  const [selectedVal, setSelectedVal] = useState<CreditScoreCriterions>()
  const { fetchCreditScoreCriteria, creditScore, updateCreditScore, recalculateAllCreditScore } = ConfigurationService()
  const { getRiseConfig, riseConfigMeta } = MetaService()
  const [showCreditScoreAlert, setShowCreditScoreAlert] = useState(false)
  const [creditScoreData, setCreditScoreData] = useState<CreditScoreCriterions[]>()
  const [formType, setFormType] = useState<string>('')
  const [openApprovalThresholdFormV2, setOpenApprovalThresholdFormV2] = useState(false)
  const [openApprovalThresholdFormV3, setOpenApprovalThresholdFormV3] = useState(false)
  const [openPhoneApprovalThresholdFormV2, setOpenPhoneApprovalThresholdFormV2] = useState(false)
  const [openPhoneApprovalThresholdFormV3, setOpenPhoneApprovalThresholdFormV3] = useState(false)
  const emptyData = new CreditScoreCriterions()
  const [isMobile, setIsMobile] = useState(false);

  const updateData = (data: CreditScoreCriterions[]) => { data && setCreditScoreData(data) }

  const handleSendData = (allData: CreditScoreCriterions[]) => {

    const activeCriteria = allData?.filter(criteria => criteria?.active)     // Factor weight must be added only for active criteria
    const totalWeightage = Number(activeCriteria?.reduce((total, data) => total + (Number(data?.criteria?.factorWeight) ?? 0), 0))
    
    if(totalWeightage !== 100){
      Notification({
        message: "Sum of factor weight must be 100",
        description: "Please update the factor weight for the criteria!",
        type: NotificationTypes.ERROR,
      });
    } else {
      allData?.map(data => {
        delete data?.criteria?.optionsArrays
        delete data?.criteria?.questions
        delete data?.createdAt
        delete data?.updatedAt
      })
      updateCreditScore(allData!)
    }
  }
  
  useEffect(() => {
    fetchCreditScoreCriteria()
    getRiseConfig()
    if (window.innerWidth <= mobileScreenWidth) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    setCreditScoreData(creditScore)
  }, [creditScore])

  return (
      <Fragment>
        <ModalComponent
            type={isMobile ? 'large' : "alert-large"}
            visible={showCreditScoreAlert}
            closeHandler={() => setShowCreditScoreAlert(false)}
            successProps={{
              title: "Yes",
              loading: loading,
              clickHandler: () => {
                recalculateAllCreditScore();
                setShowCreditScoreAlert(false)
              },
            }}
            cancelProps={{
              title: "No",
              clickHandler: () => setShowCreditScoreAlert(false),
            }}
        >
          <div className="alert-info">
            <img src={alertIcon} alt="alert-refund"/>
            <b>Are you sure you want to recalculate all users credit score?</b>
          </div>
        </ModalComponent>
        {
          loading ?
              <CardSkeletonComponent rows={4}/> :
              <>
                <div className='creditscoreconfiguration'>
                  <CreditScoreConfigurationForm
                      visible={isCreditScoreConfigFormShown}
                      closeHandler={() => setIsCreditScoreConfigFormShown(false)}
                    data={selectedVal!}
                    allData={creditScoreData}
                    updateData={updateData}
                    type={formType}
                />
                <ApprovalThresholdForm visible={openApprovalThresholdFormV2} closeHandler={() => setOpenApprovalThresholdFormV2(false)} data={riseConfigMeta} handleRefresh={getRiseConfig} type='Basic V2' />
                  <ApprovalThresholdForm visible={openApprovalThresholdFormV3} closeHandler={() => setOpenApprovalThresholdFormV3(false)} data={riseConfigMeta} handleRefresh={getRiseConfig} type='Basic V3' />
                <ApprovalThresholdForm visible={openPhoneApprovalThresholdFormV2} closeHandler={() => setOpenPhoneApprovalThresholdFormV2(false)} data={riseConfigMeta} handleRefresh={getRiseConfig} type='Phone V2' />
                <ApprovalThresholdForm visible={openPhoneApprovalThresholdFormV3} closeHandler={() => setOpenPhoneApprovalThresholdFormV3(false)} data={riseConfigMeta} handleRefresh={getRiseConfig} type='Phone V3' />
                <>
                  <Row gutter={4}>
                  <Col span={20} className="label">Credit score rating configuration</Col>
                  <Col span={4}>
                    <span className='send-data' onClick={() => setShowCreditScoreAlert(true)}>
                     <img className="icon-style-referred" src={refresh}></img>
                      Recalculate credit score                  
                    </span>
                  </Col>
                  </Row>
                  <CardComponent className='threshold-card'>
                    <div className='approval-threshold'>
                      <div className='label'>basic status approval threshold V2</div>
                      <div className='value'>{riseConfigMeta?.configJson?.thresholdCreditScoreV2} <IconComponent
                          name="icon-edit-blue" onClick={() => setOpenApprovalThresholdFormV2(true)}/>
                      </div>
                    </div>
                    <div className='phone-threshold'>
                      <div className='label'>PHONE status approval threshold V2</div>
                      <div className='value'>{riseConfigMeta?.configJson?.phoneThresholdCreditScoreV2} <IconComponent
                          name="icon-edit-blue" onClick={() => setOpenPhoneApprovalThresholdFormV2(true)}/>
                      </div>
                    </div>
                    <div className='approval-threshold'>
                      <div className='label'>basic status approval threshold V3</div>
                      <div className='value'>{riseConfigMeta?.configJson?.thresholdCreditScoreV3} <IconComponent
                          name="icon-edit-blue" onClick={() => setOpenApprovalThresholdFormV3(true)}/>
                      </div>
                    </div>
                    <div className='phone-threshold'>
                      <div className='label'>PHONE status approval threshold V3</div>
                      <div className='value'>{riseConfigMeta?.configJson?.phoneThresholdCreditScoreV3} <IconComponent
                          name="icon-edit-blue" onClick={() => setOpenPhoneApprovalThresholdFormV3(true)}/>
                      </div>
                    </div>
                  </CardComponent>
                </>

                </div>
              </>
        }
      </Fragment>
  )
}

export default CreditScoreConfiguration