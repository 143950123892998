import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import Home from "../views/Home";
import { AppRoutes } from "./routeConstants/appRoutes";
import LoginForm from "../views/Auth/LoginForm";
import ForgotPasswordForm from "../views/Auth/ForgotPasswordForm";
import NewPasswordForm from "../views/Auth/NewPasswordForm";
import Customers from "../views/Home/Customers";
import ApproveCustomersView from "../views/Home/Customers/ApproveCustomers/ApproveCustomersView";
import CustomerListView from "../views/Home/Customers/CustomerList/CustomerListView";
import Vendors from "../views/Home/Vendors";
import VendorListView from "../views/Home/Vendors/VendorList/VendorListView";
import VendorDetails from "../views/Home/Vendors/VendorList/VendorListView/VendorDetails";
import VendorStorePromotions from "../views/Home/Vendors/VendorList/VendorListView/VendorStorePromotions";
import VendorInstores from "../views/Home/Vendors/VendorList/VendorListView/VendorInstores";
import VendorSettlements from "../views/Home/Vendors/VendorList/VendorListView/VendorSettlements";
import VendorPurchaseConfig from "../views/Home/Vendors/VendorList/VendorListView/VendorPurchaseConfig";
import ApproveVendorsView from "../views/Home/Vendors/ApproveVendors/ApproveVendorsView";
import Purchases from "../views/Home/Purchases";
import PurchasesView from "../views/Home/Purchases/PurchasesView";
import Payments from "../views/Home/Payments";
import DeallUsers from "../views/Home/DeallUsers";
import Configuration from "../views/Home/Configuration";
import { LocalStorage } from "../shared/utils/localStorageHelpers";
import ActionCable from "actioncable"
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import { AuthContext } from "../context/AuthContext";
import { NotificationModel } from "../models/notification.model";
import FileView from "../shared/components/FileView";
import Dashboard from "../views/Home/Dashboard";
import InstoreFormView from "../views/Home/Vendors/VendorList/VendorListView/VendorInstores/InstoreFormView";
import InstoreView from "../views/Home/Vendors/VendorList/VendorListView/VendorInstores/InstoreView";
import { VendorsSettlements } from "../views/Home/Settlements/VendorSettlements";
import PartnerFeesSettlements from "../views/Home/Settlements/PartnerFeesSettlements";
import PastSettlementView from "../views/Home/Settlements/PastSettlementView";
import PastPartnerSettlementView from "../views/Home/Settlements/PartnerFeesSettlements/PastPartnerFeeSettlements/PastPartnerSettlementView";
import PaymentCollectionSettlements from "../views/Home/Settlements/PaymentCollectionSettlements";
import PastPaymentCollectionView from "../views/Home/Settlements/PaymentCollectionSettlements/PaymentCollectionPastSettlements/PastPaymentCollectionView";
import OtpForm from "../views/Auth/Otp";
import CustomerEditView from "../views/Home/Customers/ApproveCustomers/CustomerEditView";
import PaymentView from "../views/Home/Payments/PaymentLogs/PaymentView";
import AddPosTerminal from "../views/Home/Vendors/VendorList/VendorListView/VendorInstores/InstoreFormView/AddPosTerminal";
import Monitoring from "../views/Home/Monitoring";
import PromotionDetailView from "../views/Home/Configuration/Promotions/PromotionDetailView";
import Promotions from "../views/Home/Configuration/Promotions";

export const appHistory = createBrowserHistory();

const AppRouter = () => {

  const {user, setAuthenticated,authenticated} = AuthContext()

  const connectToCable = () => {
    const cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_CABLE_URL}?access_token=${LocalStorage.getItem("ACCESS_TOKEN")}`
    );
    cable?.subscriptions?.create(
      {
        channel: "AdminNotificationChannel",
        room: `admin_notification_${user?.id}`,
      },
      {
        received: (data: NotificationModel) => {
          Notification({
            message: "New notification",
            type: NotificationTypes.OPEN,
            description:"RISE"
          });
        },
      }
    );
  };

  useEffect(() => {
    const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
    accessToken && connectToCable();
    (accessToken && user?.id) && setAuthenticated(user);

  }, [user])

  return (
    <div className="app-router">
      <Routes>
        <Route path="*" element={<Navigate to={AppRoutes.AUTH} />}/>
        <Route path={AppRoutes.AUTH} element={<Navigate to={AppRoutes.LOGIN}/>} />
        <Route path={AppRoutes.HOME} element={isAuthenticated(<Navigate to={{ pathname: AppRoutes.DASHBOARD }} />)} />
        <Route path={AppRoutes.AUTH} element={<AuthWrapper />}>
          <Route path={AppRoutes.LOGIN} element={authenticated ? isAuthenticated(<Navigate to={{ pathname: AppRoutes.DASHBOARD }} />)  : <LoginForm />} />
          <Route path={AppRoutes.OTP} element={<OtpForm />} />
          <Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPasswordForm />} />
          <Route path={AppRoutes.NEW_PASSWORD} element={<NewPasswordForm />} />
        </Route>
        <Route path={AppRoutes.HOME} element={isAuthenticated(<Home/>)}>
          <Route path={AppRoutes.DASHBOARD} element={<Dashboard/>}/>
          <Route path={AppRoutes.FILE_VIEW} element={<FileView/>}/>
          <Route path={AppRoutes.CUSTOMERS} element={<Customers/>}/>
          <Route path={AppRoutes.CUSTOMERS_LIST_VIEW} element={<CustomerListView/>}/>
          <Route path={AppRoutes.APPROVE_CUSTOMERS_VIEW} element={<ApproveCustomersView/>}/>
          <Route path={AppRoutes.REJECTED_CUSTOMERS_VIEW} element={<ApproveCustomersView/>}/>
          <Route path={AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW} element={<CustomerEditView/>}/>
          <Route path={AppRoutes.WAITLISTED_CUSTOMERS_VIEW} element={<ApproveCustomersView/>}/>
          <Route path={AppRoutes.INCOMPLETE_ONBOARDING_CUSTOMERS_VIEW} element={<ApproveCustomersView/>}/>
          <Route path={AppRoutes.VENDORS} element={<Vendors />}/>
          <Route path={AppRoutes.VENDOR_LIST_VIEW} element={<VendorListView />}>
              <Route path={AppRoutes.VENDOR_DETAILS} element={<VendorDetails />}/>
              <Route path={AppRoutes.VENDOR_STORE_PROMOTIONS} element={<VendorStorePromotions />}/>
              <Route path={AppRoutes.VENDOR_INSTORES} element={<VendorInstores />}/>
              <Route path={AppRoutes.VENDOR_SETTLEMENTS} element={<VendorSettlements />}/>
              <Route path={AppRoutes.VENDOR_PURCHASE_CONFIG} element={<VendorPurchaseConfig />}/>
          </Route>
          <Route path={AppRoutes.VENDOR_INSTORES_CREATE} element={<InstoreFormView />}/>
          <Route path={AppRoutes.VENDOR_INSTORES_EDIT} element={<InstoreFormView />}/>
          <Route path={AppRoutes.VENDOR_INSTORES_VIEW} element={<InstoreView />}/>
          <Route path={AppRoutes.VENDOR_INSTORES_ADD_POS} element={<AddPosTerminal />}/>
          <Route path={AppRoutes.VENDOR_REJECTED_DETAILS} element={<ApproveVendorsView />}/>
          <Route path={AppRoutes.APPROVE_VENDORS_VIEW} element={<ApproveVendorsView />}/>
          <Route path={AppRoutes.PURCHASES} element={<Purchases />}/>
          <Route path={AppRoutes.PURCHASES_VIEW} element={<PurchasesView />}/>
          <Route path={AppRoutes.SETTLEMENTS} element={<Navigate to={{ pathname: AppRoutes.SETTLEMENTS_VENDOR, hash: "pending" }} />}/>
          <Route path={AppRoutes.SETTLEMENTS_PAYMENT_COLLECTION} element={<PaymentCollectionSettlements/>}/>
          <Route path={AppRoutes.SETTLEMENTS_VENDOR} element={<VendorsSettlements/>}/>
          <Route path={AppRoutes.PARTNER_FEE_SETTLEMENTS} element={<PartnerFeesSettlements />}/>
          <Route path={AppRoutes.PAST_SETTLEMENTS_VIEW} element={<PastSettlementView />}/>
          <Route path={AppRoutes.PARTNER_PAST_SETTLEMENTS_VIEW} element={<PastPartnerSettlementView />}/>
          <Route path={AppRoutes.COLLECTION_PAST_SETTLEMENTS_VIEW} element={<PastPaymentCollectionView />}/>
          <Route path={AppRoutes.PAYMENTS} element={<Payments />}/>
          <Route path={AppRoutes.PAYMENT_VIEW} element={<PaymentView />}/>
          <Route path={AppRoutes.DEALL_USERS} element={<DeallUsers />}/>
          <Route path={AppRoutes.CONFIGURATION} element={<Configuration />} />
          <Route path={AppRoutes.MONITORING} element={<Monitoring />} />
          <Route path={AppRoutes.PROMOTION_VIEW} element={<PromotionDetailView />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AppRouter;
