import { Col, Divider, Row } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { AppRoutes } from '../../../../../routes/routeConstants/appRoutes'
import CardComponent from '../../../../../shared/components/CardComponent'
import PageView from '../../../../../shared/components/PageView'
import "./promotiondetailview.scss"
import { Promotion } from '../../../../../models/Promotion/promotion.model'
import ConfigurationService from '../../../../../services/ConfigurationService/configuration.service'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import PromotionForm from '../PromotionForm'
import { BadgeTypes } from '../../../../../enums/badgeTypes'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import useLocationState from '../../../../../shared/utils/useLocationState'

interface PromotionDetailViewProps {
 }

 interface PromotionDeactivateAlertProps {
  visible: boolean;
  refreshList: () => void;
  closeHandler: () => void;
  status: BadgeTypes;
}

export const PromotionDeactivateAlert: FC<PromotionDeactivateAlertProps> = ({
  status,
  visible,
  refreshList,
  closeHandler,
}) => {
  const { loading, updatePromotion } = ConfigurationService();
  const { promotionId } = useParams();
  const isPendingOrActive = status === BadgeTypes.ACTIVE || status === BadgeTypes.PENDING
  const handleBlacklist = () => {
    const formData = {
      // When the status of the promotion is pending or active, allow the admin to deactivate the promotion.
      // If inactive allow admin to activate the promotion
       isActive: !isPendingOrActive
    };

    if (promotionId)
      updatePromotion(promotionId.toString(), formData, () => {
        closeHandler();
        refreshList();
      });
  };
  return (
    <ModalComponent
      type="alert"
      visible={visible}
      closeHandler={closeHandler}
      successProps={{
        title: isPendingOrActive ? "Deactivate" : "Activate",
        loading: loading,
        clickHandler: () => {
          handleBlacklist();
        },
      }}
      cancelProps={{
        title: "Close",
        clickHandler: () => {
          closeHandler();
        },
      }}
      alert={{
        title: `${isPendingOrActive? "Deactivate" : "Activate"} promotion`,
        description: `Are you sure you want to ${isPendingOrActive? "deactivate" : "activate"} the promotion ?`,
        ...(isPendingOrActive
          ? {  }
          : { buttonType: "affirmative" }),
      }}
    />
  );
};

const PromotionDetailView: FC<PromotionDetailViewProps> = () => {
  const { getPromotion, promotion } = ConfigurationService();
  const { promotionId } = useParams();
  const { pageNumber } = useLocationState();
  const [isPromoFormShown, setIsPromoFormShown] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDeactivateAlertShown, setIsDeactivateAlertShown] = useState(false);
  const [promotionData, setPromotionData] = useState<Promotion>();

  const isPromotionIncomplete = promotion?.status === BadgeTypes.ACTIVE || promotion?.status === BadgeTypes.PENDING || promotion?.status === BadgeTypes.INACTIVE;

  const feeData = promotion?.promoConfig?.feePercent
  const processingFeeForInstallments = feeData ? Object.entries(feeData)?.map(([key, value], index) => ({ installment: index + 2, percentage: value })).filter(installments => installments.percentage != undefined) : [];
  const isProcessingFeeSame: boolean = Number(promotion?.promoConfig?.feePercent?.all) >= 0
  const processingFeePercent = 
  <div className='processin-fee'>
    <div className='label'>Discounted processing fee %</div>
    <div className='value'>{`${promotion?.promoConfig?.feePercent?.all} %`}</div>
  </div>

  const purchaseFeePercent = 
  <div>
    <div>Purchase discount %</div>
    <div>{`${promotion?.promoConfig?.purchasePercent} %`}</div>
  </div>

  const installmentProcessingFeePercent = 
  <div className='installment-container'>
  <div className='installments header'>
    <div className='label'>Installment</div>
    <div className='value'>Discounted processing fee %</div>
  </div>
  {processingFeeForInstallments?.map(installments => 
  <div className='installments'>
    <div className='label'>{installments?.installment}</div>
    <div className='value'>{installments?.percentage}%</div>
  </div>)}
  </div>

  const closeHandler = () => {
    setIsPromoFormShown(false); 
    setIsDeactivateAlertShown(false);
  }

  const successHandler = () => {
    promotionId && getPromotion(promotionId);
    closeHandler();
  }

  useEffect(() => {
    setPromotionData({
      ...new Promotion(),
      title: promotion?.title,
      startDate: promotion?.startDate,
      endDate: promotion?.endDate,
      discountType: promotion?.discountType,
      promoConfig: {
        installmentPercentage: processingFeeForInstallments,
        feePercent: {
          two: promotion?.promoConfig?.feePercent?.two,
          three: promotion?.promoConfig?.feePercent?.three,
          four: promotion?.promoConfig?.feePercent?.four,
          five: promotion?.promoConfig?.feePercent?.five,
          all: promotion?.promoConfig?.feePercent?.all
        },
        customerValue: promotion?.promoConfig?.customerValue,
      },
      purchasePercent: promotion?.promoConfig?.purchasePercent,
      discountedProcessingFee: promotion?.promoConfig?.feePercent?.all,
      images: {
        landingBannerAr: promotion?.images?.landingBannerAr,
        landingBannerEn: promotion?.images?.landingBannerEn,
        smallBannerAr: promotion?.images?.smallBannerAr,
        smallBannerEn: promotion?.images?.smallBannerEn,
        explainerBannerAr: promotion?.images?.explainerBannerAr,
        explainerBannerEn: promotion?.images?.explainerBannerEn,
        landingBannerArId: promotion?.images?.landingBannerArId,
        landingBannerEnId: promotion?.images?.landingBannerEnId,
        smallBannerArId: promotion?.images?.smallBannerArId,
        smallBannerEnId: promotion?.images?.smallBannerEnId,
        explainerBannerArId: promotion?.images?.explainerBannerArId,
        explainerBannerEnId: promotion?.images?.explainerBannerEnId,
      },
      phone: (promotion?.promoConfig?.customerValue?.includes("phone") || promotion?.promoConfig?.customerValue?.includes("all")) ?? false,
      basic: (promotion?.promoConfig?.customerValue?.includes("basic") || promotion?.promoConfig?.customerValue?.includes("all")) ?? false,
      gold: (promotion?.promoConfig?.customerValue?.includes("gold") || promotion?.promoConfig?.customerValue?.includes("all")) ?? false,
      silver: (promotion?.promoConfig?.customerValue?.includes("silver") || promotion?.promoConfig?.customerValue?.includes("all")) ?? false,
      isProcessingFeeSame: Number(promotion?.promoConfig?.feePercent?.all) >= 0,
      });
  }, [promotion])

  useEffect(() => {
    promotionId && getPromotion(promotionId);
  },[])

    return (
      <>
        <PromotionDeactivateAlert visible={isDeactivateAlertShown} status={promotion?.status as BadgeTypes} refreshList={successHandler} closeHandler={closeHandler}  />
        <PromotionForm visible={isPromoFormShown} closeHandler={closeHandler} successHandler={successHandler} data={promotionData!} />
        <PageView
        type="back-only"
        title={{ name: promotion?.title! }}
        backRoute={`${AppRoutes.CONFIGURATION}#promotion-config`}
        vendorFormHandler={()=>setIsPromoFormShown(true)}
        blacklistHandler={()=>setIsDeactivateAlertShown(true)}
        promoStatus={promotion?.status as BadgeTypes}
        promoId={promotion?.promoId}
        pageNumber={pageNumber}
      >
        <Row gutter={[16,16]}>
          <Col span={7}>
          <CardComponent className='promotion-details'>
            {(promotion?.discountType === "fee_percentage" && !isProcessingFeeSame)  ? 
            installmentProcessingFeePercent : promotion?.discountType === "purchase_percentage" ? purchaseFeePercent 
            : (promotion?.discountType === "fee_percentage" && isProcessingFeeSame) 
            ? processingFeePercent : <></>}
            <Divider />
            <div className='processin-fee'>
              <div className='label'>Promotion date</div>
              <div className='value'>{`${moment(promotion?.startDate).format("DD MMM YYYY")} -  ${moment(promotion?.endDate).format("DD MMM YYYY")}`}</div>
            </div>
          </CardComponent>
          </Col>
          <Col span={10}>
          <CardComponent className='promotion-info'>
            <div className='container'>
              <div className='label'>Promotion ID</div>
              <div className='value'>{promotion?.promoId}</div>
            </div>
            <div className='container'>
              <div className='label'>Promotion type</div>
              <div className='value'>{`${promotion?.discountType === 'fee_percentage' ? 'Processing fee %' : 'Purchase discount %'} `}</div>
            </div>
            <div className='container'>
              <div className='label'>Applicable user plan</div>
              <div className='value'>{promotion?.promoConfig?.customerValue?.map(plan => plan.charAt(0).toUpperCase() + plan.slice(1)).join(', ')}</div>
            </div>
          </CardComponent>
          </Col>
          <Col span={17}>
          <CardComponent className='asset-info'>
          <h4>Landing page banner</h4>
            <div className='banner-container'>
              <div className='asset-1'>
                <div className='header'>English asset</div>
                <div><img className='banner' src={promotion?.images?.landingBannerEn?.s3Url} alt="" /></div>
              </div>
              <div className='asset-2'>
                <div className='header'>الأصول العربية</div>
                <div><img className='banner' src={promotion?.images?.landingBannerAr?.s3Url} alt="" /></div>
              </div>
            </div>
            <h4>Explainer page banner</h4>
            <div className='banner-container'>
              <div className='asset-1'>
                <div className='header'>English asset</div>
                <div><img className='banner explainer' src={promotion?.images?.explainerBannerEn?.s3Url} alt="" /></div>
              </div>
              <div className='asset-2'>
                <div className='header'>الأصول العربية</div>
                <div><img className='banner explainer' src={promotion?.images?.explainerBannerAr?.s3Url} alt="" /></div>
              </div>
            </div>
            <h4>Small banner</h4>
            <div className='banner-container'>
              <div className='asset-1'>
                <div className='header'>English asset</div>
                <div><img className='banner small' src={promotion?.images?.smallBannerEn?.s3Url} alt="" /></div>
              </div>
              <div className='asset-2'>
                <div className='header'>الأصول العربية</div>
                <div><img className='banner small' src={promotion?.images?.smallBannerAr?.s3Url} alt="" /></div>
              </div>
            </div>
          </CardComponent>
          </Col>
        </Row>
      </PageView>
      </>
    )
}

export default PromotionDetailView