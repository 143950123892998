import React, {FC, Fragment, ReactNode, useEffect, useState} from "react";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import "./approveCustomersControllers.scss";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import {CustomerModel} from "../../../../../../models/Customer/customer.model";
import {BadgeTypes} from "../../../../../../enums/badgeTypes";
import {generatePath, useLocation, useNavigate} from "react-router-dom";
import InputField from "../../../../../../shared/components/InputField";
import {Form, Formik} from "formik";
import {approveCustomerFormValidation} from "./approveCustomerFormValidation";
import CustomerDetailsForm from "../../../CustomerDetailsForm";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
import {Col, Drawer, Row, Tooltip} from "antd";
import {AppRoutes} from "../../../../../../routes/routeConstants/appRoutes";
import MenuComponent from "../../../../../../shared/components/MenuComponent";
import IconComponent from "../../../../../../shared/components/IconComponent";
import deleteAlert from "../../../../../../assets/images/delete-alert.png"
import DropdownField from "../../../../../../shared/components/DropdownField";
import MetaService from "../../../../../../services/MetaService/meta.service";
import {AcquisitionDefinitionTypes} from "../../../../../../definitions/acquisitionTypes";
import {AcquisitionTypes} from "../../../../../../enums/acquisitionTypes";
import alertIcon from "../../../../../../assets/images/alert-for-refund-creation.svg"
import upgradeIcon from "../../../../../../assets/images/upgrade-icon.svg"
import lockIcon from "../../../../../../assets/images/lock-icon.svg"
import TooltipComponent from "../../../../../../shared/components/TooltipComponent";
import {getInfoIcon} from "../../../../../../shared/utils/getInfoIcon";
import infoIcon from "../../../../../../assets/images/icon-grey.svg"
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import Notification from "../../../../../../shared/components/Notification";
import { approvalFormValidationSchema } from "../../../CustomerDetailsForm/customerDetailsFormValidation";
import Approvaldrawer from "../../../../../../shared/components/ApprovalDrawer";
import { mobileScreenWidth } from "../../../../../../enums/constants";

interface ApproveCustomersControllersProps {
  customer: CustomerModel | undefined;
  handleRefresh: () => void
}

interface Menu {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  clickHandler?: () => void;
}

interface DocumentDetails {
  customerAttachmentId?: string | number;
  s3Url?: string;
  filename?: string;
  fileType?: string;
  attachmentType?: string;
}

const ApproveCustomersControllers: FC<ApproveCustomersControllersProps> = (
  props
) => {
  const { customer, handleRefresh } = props;
  const [showRejectAlert, setRejectAlertVisibility] = useState(false);
  const [showApproveAlert, setApproveAlertVisibility] = useState(false);
  const [dataIncomplete, setDataIncomplete] = useState(false)
  const [docVerificationIncomplete, setDocVerificationIncomplete] = useState(false)
  const [editCustomerDetails, setEditCustomerDetails] = useState(false);
  const [saveCustomerDetails, setSaveCustomerDetails] = useState(false);
  const [isApproveFlow, setIsApproveFlow] = useState(false);
    const {
        updateCustomerDetails,
        manualApproveCustomer,
        loading,
        deleteCustomerData,
        fetchCities,
        cities,
        updateNeighbourhood,
        fetchProfessionalGroupList,
        professionalGroup,
        reclassifyPhoneCustomer
    } = CustomerService();
  const {fetchApprovalReasonList, approvalReasonList , fetchRejectionReasonList, rejectionReasonList, getRiseConfig, riseConfigMeta } = MetaService()
  const [isBlacklistAlertShown, setIsBlacklistAlertShown] = useState(false);
  const [isReclassifyAlertShown, setIsReclassifyAlertShown] = useState(false);
  const [isReclassifyWarningShown, setIsReclassifyWarningShown] = useState(false);
  const [isPermanentlyDeleteAlertShown, setIsPermanentlyDeleteAlertShown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showRecommendationWarning, setShowRecommendationWarning] = useState(false)
  const [engineDecisionDrawerVisible, setEngineDecisionDrawerVisible] = useState(false)
  const [decision, setDecision] = useState<string>('')
  const [redirection, setRedirection] = useState<'approval' | 'rejection' | undefined>();
  const [showRemoveSuspension, setShowRemoveSuspension] = useState(false);

  const navigate = useNavigate();
  const user = LocalStorage.getItem('USER') as UserModel
  const location = useLocation();
  const calculatorVersion = customer?.creditScoreBreakdown?.calculator === 'v3' ? 'V3' : 'V2';
  const thresholdKey = `thresholdCreditScore${calculatorVersion}` as 'thresholdCreditScoreV2' | 'thresholdCreditScoreV3';
  const configMeta = !!riseConfigMeta?.configJson?.[thresholdKey];

  const [rejectDrawerVisible, setRejectDrawerVisible] = useState(false)
  const [approveDrawerVisible, setApproveDrawerVisible] = useState(false)

  const systemApprovalDecision = customer?.engineDecision;
  const isApproved = customer?.status === BadgeTypes.APPROVED || customer?.status === BadgeTypes.INACTIVE;
  const isCustomerActive = customer?.active;
  const isApprovedOrWaitlistOrRejected = customer?.status === BadgeTypes.APPROVED || customer?.status === BadgeTypes.WAITLISTED || customer?.status === BadgeTypes.REJECTED;
  const isApprovedInactive = customer?.status === BadgeTypes.APPROVED && !isCustomerActive;
  const isWaitlisted = customer?.status === BadgeTypes.WAITLISTED
  const isPendingApproval = customer?.status === BadgeTypes.PENDING_APPROVAL
  const isRejected = customer?.status === BadgeTypes.REJECTED
  const isDetailsMissing = !customer?.detailsAvailable;
  const pendingDocumentCount = customer?.pendingDocumentCount ?? 0;
  const isDocVerificationIncomplete = customer?.hasPendingAttachment;
  const  suspendedUser = customer?.isSuspended;

  const openDrawer = () => setApproveDrawerVisible(true);
  const closeDrawer = () => setApproveDrawerVisible(false);
  const refreshCities = () => fetchCities();

  const docApprovalTitle = "Please verify additional document uploaded"
  const docApprovalDescription = "To reject the customer please approve or reject the additional document uploaded by the customer"
  const handleBlacklist = (deactivationReason: string) => {
    setIsBlacklistAlertShown(true);
    updateCustomerDetails(
      {
        active: !isCustomerActive,
        id: customer?.id,
        deactivationReason
      },
      () => {
        setIsBlacklistAlertShown(false);
        handleRefresh()
      }
    );
  };

  const handleApprove = (comment: string, commentId: number[]) => {
    manualApproveCustomer(
        {
            status: BadgeTypes.APPROVED,
            id: customer?.id,
            approvalReason: comment,
            approvalReasonIds: commentId
        },
        customer?.id?.toString() ?? "",
        () => {
            setApproveAlertVisibility(false);
            setApproveDrawerVisible(false);
            navigate(-1);
        }
    );
  };

    const handleReject = (comment: string, commentId: number[], acquisitionChannel: string | undefined) => {
        if(commentId.length > 0 && comment){
            updateCustomerDetails(
                {
                    status: BadgeTypes.REJECTED,
                    id: customer?.id,
                    rejectedReason: comment,
                    rejectedReasonIds: commentId,
                    acquisitionChannel: acquisitionChannel ?? "organic"
                },
                () => {
                    setRejectAlertVisibility(false);
                    setRejectDrawerVisible(false);
                    navigate(-1);
                }
            );
        }
    };

  const verifyDocumentApproval = () => {
    setRedirection('rejection')
    systemApprovalDecision ? setShowRecommendationWarning(true) : isMobile ? setRejectDrawerVisible(true) : setRejectAlertVisibility(true) 
  }

  const checkSystemRecommendation = () => {
    setRedirection('approval');
    (isPendingApproval || isRejected) && setIsApproveFlow(true);
    !systemApprovalDecision && customer?.status === BadgeTypes.PENDING_APPROVAL ? setShowRecommendationWarning(true) : isMobile ? navigate(generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, { customerId: customer?.id?.toString() }), { state: { isApprove: true, customer, isSave: false, redirection: 'approval' } }) : setEditCustomerDetails(true)
  }

  const documentTypes = [
    'bankStatement',
    'vehicleCard',
    'sportsClubMembership',
    'paySlip',
    'creditCardStatement',
    'propertyContract',
    'employmentLetter',
    'employmentLetterMedicalCard',
    'paySlipBankStatement',
    'medicalCard',
    'studentCard'
] as const;

type DocumentType = (typeof documentTypes)[number];

const getPendingDocumentDetails = (documentType: DocumentType): DocumentDetails | null => {
    const document = customer?.[documentType]?.[0];
    if (document?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION) {
        return {
            customerAttachmentId: document.customerAttachmentId,
            s3Url: document.s3Url,
            filename: document.filename,
            fileType: document.fileType,
            attachmentType: document.attachmentType,
        };
    }
    return null;
};

const checkPendingDocs = (): DocumentDetails | null => {
    for (const documentType of documentTypes) {
        const details = getPendingDocumentDetails(documentType);
        if (details) {
            return details;
        }
    }
    return null;
};

const checkIfMandatoryFieldsPresent = (customer: CustomerModel) => {
  const isfirstNameEmpty = !customer.firstName
  const islastNameEmpty = !customer.lastName
  const isnationalIdNumberEmpty = !customer.nationalIdNumber
  const isdateOfBirthEmpty = !customer.dateOfBirth
  const isaddressEmpty = !customer.address

  return (isfirstNameEmpty || islastNameEmpty || isnationalIdNumberEmpty || isdateOfBirthEmpty || isaddressEmpty)
}

const redirectToFileView = () => {
    const pendingDocumentDetails = checkPendingDocs();
    if (pendingDocumentDetails) {
        const { customerAttachmentId, s3Url, filename, fileType, attachmentType } = pendingDocumentDetails;
        navigate(
            generatePath(AppRoutes.FILE_VIEW, { fileId: `${customerAttachmentId}` }),
            { state: { s3Url, filename, fileType, attachmentType, customerStatus: customer?.status, from: 'customer' } }
        );
    }
};

const configJson = riseConfigMeta?.configJson;
const isPhoneUser = customer?.isPhoneOnlyUser;
// For a phone user to be upgraded to basic, the credit score of the customer must be > than basic threshold score
const didPhoneUserPassThreshold = (customer?.creditScore ?? 0) >= (configJson?.[thresholdKey] ?? 0);

let menuItems: Menu[] = [];

if(customer?.eligibleForBasic){
  menuItems.push({
    title: `Upgrade to basic customer`,
    subtitle: <div className="subtitle"><span className="title">Engine decision: </span><span className={`description ${didPhoneUserPassThreshold ? 'approve' : 'decline'} `}>{didPhoneUserPassThreshold ? 'Approve' : 'Decline'} upgrade</span></div>,
    clickHandler: () => didPhoneUserPassThreshold ?  setIsReclassifyAlertShown(true) : setIsReclassifyWarningShown(true),
    icon:  <img src={upgradeIcon} alt="upgrade" />,
  })
}

if(!suspendedUser){
  menuItems.push({
    title: `${isCustomerActive ? "Deactivate" : "Activate"} Customer`,
    clickHandler: () => setIsBlacklistAlertShown(true),
    icon: <IconComponent name="icon-blacklist" />,
  })
}

if(suspendedUser){
  menuItems.push({ 
    title: `Remove suspension`,
    clickHandler: () => setShowRemoveSuspension(true),
    icon:  <img src={lockIcon} alt="suspension" />,
   })
}

const deleteCustomer = {
  title: "Permanently delete customer",
  clickHandler: () => setIsPermanentlyDeleteAlertShown(true),
  icon: <img src={deleteAlert} alt="delete" />,
}

  const controllerButton = (isEdit: boolean) => (
    (isEdit) ?
      <>
      <ButtonComponent
        size="medium"
        type="primary"
        additionalTypes={isApprovedInactive ? "affirmative" : isApprovedOrWaitlistOrRejected ? "primary" : "affirmative"}
        onClick={checkSystemRecommendation}
      >
        {(isApproved || isWaitlisted) ? "Edit Details" : isRejected ? "Edit & Approve" : isPendingApproval ? "Approve" : ""}

      </ButtonComponent></> :
      <div className="right-button">
      {isPendingApproval ? 
      <ButtonComponent
        size="medium"
        additionalTypes="negative"
        onClick={verifyDocumentApproval}
      >
        Reject
      </ButtonComponent> : <></> }
        {isApproved && (isMobile ?
          <ButtonComponent
            size="medium"
            additionalTypes="negative"
            onClick={() => setIsBlacklistAlertShown(true)}
          >
            {isCustomerActive ? "Deactivate" : "Activate"} Customer
          </ButtonComponent> :
          <MenuComponent
            dropDownPlacement="topCenter"
            dropdownClassName="customer-reject-menu"
            menu={[...menuItems, deleteCustomer]}
          />)}
      </div>
  )

  useEffect(() => {
    customer?.id && fetchRejectionReasonList(customer?.id);
    fetchApprovalReasonList()
    getRiseConfig()
    fetchCities()
    fetchProfessionalGroupList()
    
    if (window.innerWidth <= mobileScreenWidth) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
   setDecision(customer?.engineDecision ? "approve" : "reject")
  }, [systemApprovalDecision])

  const addressDocumentsVerified = !isDocVerificationIncomplete && !isDetailsMissing
  const detailsNotverified = isDocVerificationIncomplete && isDetailsMissing
  const pendingApprovalLargeScreen = !isMobile && isPendingApproval
  const applyGutter = isDocVerificationIncomplete && isDetailsMissing && isMobile && isPendingApproval
  const pendingApprovalSmallScreen = isMobile && isPendingApproval
  const isIncompleteOnboarding = location.pathname.includes("incomplete-onboarding-customers")
  const pendingApprovalLgVerificationPending = pendingApprovalLargeScreen && !isIncompleteOnboarding && !isDetailsMissing

  const setDetailsTrue = () => {setSaveCustomerDetails(true); setEditCustomerDetails(true)}

  const basicOrPhone = customer?.isPhoneOnlyUser ? 'phone user' : 'basic user'

  return (
    <Formik
      initialValues={{ comment: "", rejectionComment: "", approvalComment: "", approvalReasonId: [], rejectionReasonId: [], acquisitionChannel: "", neighbourhood: null, deactivationReason: "" }}
      onSubmit={() => { }}
      validationSchema={approveCustomerFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Fragment>
          <ModalComponent
            type={isMobile ? 'large' : "alert-large"}
            title="Some of the customer details are empty. Are you sure you want to continue?"
            visible={dataIncomplete}
            closeHandler={() => setDataIncomplete(false)}
            successProps={{
              title: "Continue",
              loading: loading,
              clickHandler: () => {
                setDataIncomplete(false)
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => setDataIncomplete(false),
            }}
          />
          <Formik onSubmit={() => {}} validationSchema={approvalFormValidationSchema} initialValues={{ approvalComment: "", approvalReasonId: [] }} enableReinitialize>
          {({values, setFieldValue, handleSubmit, errors}) => (
            <Form>
              <ModalComponent
                type="alert-large"
                alert={{
                    title: "Are you sure you want to approve the customer ?",
                    buttonType: "affirmative"
                }}
                visible={showApproveAlert}
                closeHandler={() => setApproveAlertVisibility(false)}
                successProps={{
                    title: `Approve ${basicOrPhone}`,
                    loading: loading,
                    clickHandler: () => {
                      handleSubmit()
                      if (!Object.keys(errors).length) { handleApprove(values.approvalComment, values.approvalReasonId); }
                    },
                }}
                cancelProps={{
                    title: "Close",
                    clickHandler: () => setApproveAlertVisibility(false),
                }}
              >
                <DropdownField
                    mode="multiple"
                    maxTagCount="responsive"
                    optionFilterProp="label"
                    allowClear
                    label="Reason for approval"
                    placeholder="Select"
                    name="approvalReasonId"
                    value={values?.approvalReasonId}
                    options={approvalReasonList}
                    onChange={(value) => {
                        setFieldValue("approvalReasonId", value);
                    }}
                />
                <InputField textArea type="text" name="approvalComment" label="Comment" placeholder="Enter" />
              </ModalComponent>
            </Form>
          )}
          </Formik>

          <ModalComponent
            type="alert-large"
            alert={{
              title: "Are you sure you want to reject the customer ?",
              buttonType: "negative"
            }}
            visible={showRejectAlert}
            closeHandler={() => setRejectAlertVisibility(false)}
            successProps={{
              title: `Reject ${basicOrPhone}`,
              loading: loading,
              clickHandler: () => {
                handleReject(values.rejectionComment, values.rejectionReasonId, values.acquisitionChannel);
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => setRejectAlertVisibility(false),
            }}
          >
            <DropdownField
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              label="Reason for rejection"
              placeholder="Select"
              name="rejectionReasonId"
              value={values?.rejectionReasonId}
              options={rejectionReasonList}
              onChange={(value) => {
                setFieldValue("rejectionReasonId", value);
              }}
            />
            <InputField textArea type="text" name="rejectionComment" label="Comment" placeholder="Enter" />
            <DropdownField
              label="Acquisition channel"
              placeholder="Select"
              name="acquisitionChannel"
              defaultValue="Organic"
              value={values?.acquisitionChannel}
              options={[
                  {
                      value: AcquisitionTypes.ORGANIC,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.ORGANIC],
                  },
                  {
                      value: AcquisitionTypes.RISE_REFERRAL_PROGRAM,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.RISE_REFERRAL_PROGRAM],
                  },
                  {
                      value: AcquisitionTypes.USHER,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.USHER],
                  },
                  {
                      value: AcquisitionTypes.AMBASSADOR,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.AMBASSADOR],
                  },
                  {
                      value: AcquisitionTypes.CORPORATE_DEAL,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.CORPORATE_DEAL],
                  },
              ]}
              onChange={(value) => setFieldValue("acquisitionChannel", value)}
            />
          </ModalComponent>

          {customer && <Approvaldrawer customer={customer} visible={approveDrawerVisible} openDrawer={openDrawer} closeDrawer={closeDrawer} />}
          <Drawer
            placement="bottom"
            onClose={() => setRejectDrawerVisible(false)}
            visible={rejectDrawerVisible}
            key="left"
            className="reject-customer-drawer"
          >
            <h3 className="reject-drawer-header">Are you sure you want to reject the customer ?</h3>
            <DropdownField
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              label="Reason for rejection"
              placeholder="Select"
              name="rejectionReasonId"
              value={values?.rejectionReasonId}
              options={rejectionReasonList}
              onChange={(value) => {
                setFieldValue("rejectionReasonId", value);
              }}
            />
            <InputField className="reject-drawer-input" textArea type="text" name="rejectionComment" label="Comment" placeholder="Enter" />
            <DropdownField
              label="Acquisition channel"
              defaultValue="Organic"
              placeholder="Select"
              name="acquisitionChannel"
              value={values?.acquisitionChannel}
              options={[
                {
                  value: AcquisitionTypes.ORGANIC,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.ORGANIC],
              },
              {
                  value: AcquisitionTypes.RISE_REFERRAL_PROGRAM,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.RISE_REFERRAL_PROGRAM],
              },
              {
                  value: AcquisitionTypes.USHER,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.USHER],
              },
              {
                  value: AcquisitionTypes.AMBASSADOR,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.AMBASSADOR],
              },
              {
                  value: AcquisitionTypes.CORPORATE_DEAL,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.CORPORATE_DEAL],
              },
              ]}
              onChange={(value) => setFieldValue("acquisitionChannel", value)}
            />
            <Row gutter={12} className="reject-drawer-controllers">
              <Col span={12}>
                <ButtonComponent
                  type="default"
                  additionalTypes="secondary"
                  size="fixed"
                  onClick={() => setRejectDrawerVisible(false)}
                >
                  Close
                </ButtonComponent>
              </Col>
              <Col span={12}>
                <ButtonComponent
                  type={"primary"}
                  htmlType={"submit"}
                  size="fixed"
                  additionalTypes="negative"
                  onClick={() => handleReject(values.rejectionComment, values?.rejectionReasonId, values.acquisitionChannel)}
                  loading={loading}
                >
                  {`Reject ${basicOrPhone}`}
                </ButtonComponent>
              </Col>
            </Row>
          </Drawer>
          <Drawer
            placement="bottom"
            onClose={() => setEngineDecisionDrawerVisible(false)}
            visible={engineDecisionDrawerVisible}
            key="left"
            className="engine-decision-drawer"
          >
            <div className="engine-decision-drawer-header">Engine Decision:</div>
            <div className="engine-decision-drawer-content">This setting recommends approval or reject based on the credit score of the user.</div>
          </Drawer>
          <ModalComponent
            type="alert"
            visible={isBlacklistAlertShown}
            closeHandler={() => setIsBlacklistAlertShown(false)}
            successProps={{
              title: isCustomerActive ? "Deactivate" : "Activate",
              loading: loading,
              clickHandler: () => {
                values?.deactivationReason && handleBlacklist(values?.deactivationReason);
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => {
                setIsBlacklistAlertShown(false);
              },
            }}
            alert={{
              title: `Are you sure you want to ${isCustomerActive ? "deactivate" : "activate"
                } the customer ?`,
              description:
              isCustomerActive
                  ? "Customer will not be able to make purchases"
                  : "",
              ...(!isCustomerActive
                ? { buttonType: "affirmative" }
                : {}),
            }}
          >
            {isCustomerActive &&
            <InputField className="reject-drawer-input" textArea type="text" name="deactivationReason" label="Reason for deactivation" placeholder="Enter" />
            }
          </ModalComponent>

          <ModalComponent
            type="alert"
            visible={isPermanentlyDeleteAlertShown}
            closeHandler={() => setIsPermanentlyDeleteAlertShown(false)}
            successProps={{
              title: "Yes, Delete",
              loading: loading,
              clickHandler: () => {
                deleteCustomerData(customer?.id?.toString() ?? "", () => navigate(-1))
              },
            }}
            cancelProps={{
              title: "No",
              clickHandler: () => {
                setIsPermanentlyDeleteAlertShown(false);
              },
            }}
            alert={{
              title: "Permanently delete customer",
              description: "User will be permanently deleted from the database. Are you sure you want to continue?",
            }}
          />

          <ModalComponent
            type="alert"
            visible={showRemoveSuspension}
            closeHandler={() => setShowRemoveSuspension(false)}
            successProps={{
              title: "Confirm",
              loading: loading,
              clickHandler: () => {
                updateCustomerDetails(
                  {
                    isSuspended: false,
                    id: customer?.id,
                  },
                  () => {
                    setShowRemoveSuspension(false);
                    handleRefresh()
                  }
                );
              },
            }}
            cancelProps={{
              title: "No",
              clickHandler: () => {
                setShowRemoveSuspension(false);
              },
            }}
            alert={{
              title: "Are you sure you want to remove customer suspension ?",
              description: "Customer will be able to make purchases after removing suspension",
              buttonType: "affirmative"
            }}
          />

          <ModalComponent
            type={isMobile ? 'small' : "alert-large"}
            title={isMobile ? docApprovalTitle : "" }
            description={isMobile ? docApprovalDescription : ""}
            alert={{
              title: docApprovalTitle,
              description: docApprovalDescription,
              buttonType: "primary"
            }
            }
            visible={docVerificationIncomplete}
            closeHandler={() => setDocVerificationIncomplete(false)}
            successProps={{
              title: "View document",
              loading: loading,
              clickHandler: () => {
                setDocVerificationIncomplete(false)
              },
            }}
          />

          <ModalComponent
              type={isMobile ? 'small' : "alert-large"}
              visible={showRecommendationWarning}
              closeHandler={() => setShowRecommendationWarning(false)}
              successProps={{
                  title: "Yes, Proceed",
                  loading: loading,
                  clickHandler: () => {
                      setShowRecommendationWarning(false);
                      const dataNotPresent: boolean = checkIfMandatoryFieldsPresent(customer!)
                      if (redirection === 'approval') {
                        if (!dataNotPresent){
                          if (isMobile) {
                            setApproveDrawerVisible(true);
                          } else {
                              setApproveAlertVisibility(true)
                          }
                        }
                        else{
                          Notification({
                            message: 'Mandatory fields empty!',
                            description: "Please fill all the mandatory fields",
                            type: NotificationTypes.ERROR,
                          });

                          isMobile 
                          ? 
                          navigate(
                              generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, {customerId: customer?.id?.toString()}),
                              {state: {isApprove: true, customer, isSave: false, redirection: 'approval' }}
                          )
                          : setEditCustomerDetails(true);
                        }
                      } else if (redirection === 'rejection') {
                          if (isMobile) {
                              setRejectDrawerVisible(true);
                          } else {
                              setRejectAlertVisibility(true);
                          }
                      }

                  },
              }}
          >
              <div className="alert-info">
                  <img src={alertIcon} alt="alert-refund"/>
                  <h3>{`${systemApprovalDecision ? "Reject" : "Approve"} against recommendation`}</h3>
                  <p>{`you are about to ${systemApprovalDecision ? "reject" : "approve"} a user against the system recommendations`}</p>
              </div>
          </ModalComponent>

          <ModalComponent
                type="alert-large"
                visible={isReclassifyAlertShown}
                closeHandler={() => setIsReclassifyAlertShown(false)}
                successProps={{
                    title: "Yes, Proceed",
                    loading: loading,
                    clickHandler: () => {
                      setIsReclassifyAlertShown(false);
                      reclassifyPhoneCustomer(customer?.id?.toString()!, true, handleRefresh)
                    },
                }}
                cancelProps={{
                  title: "Close",
                  clickHandler: () => {
                    setIsReclassifyAlertShown(false);
                  },
                }}
            >
                <div className="alert-info">
                    <h3>Are you sure you want to upgrade the user to basic customer?</h3>
                    <p>Upon confirmation, the user will be upgraded to basic purchasing status customer</p>
                </div>
          </ModalComponent>

           <ModalComponent
              type="alert-large"
              visible={isReclassifyWarningShown}
              closeHandler={() => setIsReclassifyWarningShown(false)}
              successProps={{
                  title: "Continue",
                  loading: loading,
                  clickHandler: () => {
                    setIsReclassifyWarningShown(false)
                    setIsReclassifyAlertShown(true);
                  },
              }}
          >
              <div className="alert-info">
                  <img src={alertIcon} alt="alert-refund"/>
                  <h3>Upgrade against recommendation</h3>
                  <p>you are about to upgrade a user with lower credit score as basic customer</p>
              </div>
          </ModalComponent> 

          <CustomerDetailsForm
            visible={editCustomerDetails}
            closeHandler={() => { setEditCustomerDetails(false); setSaveCustomerDetails(false); setIsApproveFlow(false)}}
            openHandler={() => setEditCustomerDetails(true)}
            data={customer}
            successHandler={() => {
              handleRefresh()
              customer?.id && fetchRejectionReasonList(customer.id)
              setEditCustomerDetails(false)
            }
            }
            isApprove={isApproveFlow}
            isSave={saveCustomerDetails}
            redirection={redirection}
            openApproveAlertVisibility={() => setApproveAlertVisibility(true)}
            professionalGroupList={professionalGroup}
            cities={cities}
            refreshCities={refreshCities}
          />
          {user?.role !== BadgeTypes.VIEWER && (
            <>
            <div className={`approve-customers-controllers ${(isIncompleteOnboarding && isMobile && isPendingApproval) && 'hide-controller'}`}>
              <Row align="middle" className="approve-customers-controllers-row" gutter={applyGutter ? [16, 16] : 10}>
                <Col sm={0} xs={0} lg={(pendingApprovalLgVerificationPending) ? 2 : 0}> {(pendingApprovalLgVerificationPending) ? <IconComponent name="icon-edit-blue" onClick={() => {setSaveCustomerDetails(true); setEditCustomerDetails(true);}} /> : <></>}</Col>
                {(!isPendingApproval || (addressDocumentsVerified && isPendingApproval && !isIncompleteOnboarding)) &&
                <>
                <Col sm={11} xs={11} md={12} lg={(pendingApprovalLargeScreen) ? 8 : 20}>
                  {controllerButton(!isMobile)}
                </Col>
                <Col sm={11} xs={11} md={12} lg={(pendingApprovalLargeScreen) ? 7 : 4}>
                  {controllerButton(isMobile)}
                </Col>
                <Col sm={0} xs={0} lg={(pendingApprovalLargeScreen) ? 7 : 0}>
                {(pendingApprovalLargeScreen && !isIncompleteOnboarding) ? 
                <>
                  <div className="label">Engine decision </div>
                  <div className={`value ${decision}`}>{configMeta && (systemApprovalDecision ? " Approve" : " Reject")} {configMeta && (<TooltipComponent content="Engine Decision: This setting recommends approval or reject based on the credit score of the user." iconType={systemApprovalDecision ? "accept" : "reject"} />)}</div>
                </> 
                :  <></>}
                </Col>
                </>
                }
                {!isIncompleteOnboarding && 
                <>
                  {
                    (isDetailsMissing && isPendingApproval) &&
                    <>
                      {(!isDocVerificationIncomplete && !isMobile) &&
                        <Col sm={11} xs={11} md={12} lg={10}>
                          <div>Verify customer details to approve or reject customer</div>
                        </Col>
                      }
                      <Col sm={24} xs={24} md={12} lg={10}>
                      <ButtonComponent
                        size="medium"
                        type="primary"
                        additionalTypes="primary"
                        onClick={() => isMobile ? navigate(generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, { customerId: customer?.id?.toString() }), { state: { isApprove: false, customer, isSave: true } }) : setDetailsTrue()}
                      >
                        Verify Details
                      </ButtonComponent>
                      </Col>
                    </>
                  }
                  {(isDocVerificationIncomplete && isPendingApproval) &&
                    <>
                      {(!isDetailsMissing && !isMobile) &&
                      <Col sm={11} xs={11} md={10} lg={9}>
                        <div>Verify documents to approve or reject customer</div>
                      </Col>}

                      <Col sm={24} xs={24} md={10} lg={12}>
                      <ButtonComponent
                        size="medium"
                        type="primary"
                        additionalTypes="primary"
                        onClick={() => redirectToFileView()}
                      >
                        {pendingDocumentCount > 1 ? `Verify Documents (${pendingDocumentCount})` : 'Verify Document'}
                      </ButtonComponent>
                      </Col>
                    </>
                  }
                  {detailsNotverified && pendingApprovalLargeScreen && <>
                    <Col offset={1} sm={1} xs={1} md={1} lg={1}>
                      <Tooltip title="Verify documents uploaded by customer and customer details approve or reject customer ">
                        <span className={`refresh-icon`}>
                          <img src={infoIcon}></img>                    
                        </span>   
                      </Tooltip>
                    </Col>
                  </>}
                </> }
              </Row>
              {(!isIncompleteOnboarding) && 
              <Row>
                {pendingApprovalSmallScreen && (isDetailsMissing || isDocVerificationIncomplete) ? 
                  <div className="verification-label">
                    <div className="label">Verify above details to approve or reject customer</div>
                  </div>
                : (pendingApprovalSmallScreen && configMeta && !isDetailsMissing && !isDocVerificationIncomplete) ?
                  <div className={`system-decision ${decision}`}>
                    <div className={`label ${decision}`}>
                      <img
                        className="info-icon"
                        src={getInfoIcon(decision)}
                        alt=""
                        onClick={() => setEngineDecisionDrawerVisible(true)}
                      />
                      Engine Decision
                      <span className={`value ${decision}`}>
                        {systemApprovalDecision ? " Approve" : " Reject"}
                      </span>
                    </div>
                  </div> : <></>
                }
              </Row>}
            </div>
            </>
          )
          }
        </Fragment>
      )}
    </Formik>
  );
};

export default ApproveCustomersControllers;
