import { Formik, Form, FormikProps, FormikValues } from 'formik';
import React, { FC, useRef } from 'react';
import { CreditScoreConfig } from '../../../../../models/CreditScoreConfig/creditScoreConfig.model';
import MetaService from '../../../../../services/MetaService/meta.service';
import InputField from '../../../../../shared/components/InputField';
import ModalComponent from '../../../../../shared/components/ModalComponent';
import { approvalThresholdFormValidation } from './approvalThresholdFormValidation';

interface ApprovalthresholdformProps {
    visible: boolean;
    closeHandler: () => void;
    data?: CreditScoreConfig;
    handleRefresh: () => void;
    type: 'Basic V2' | 'Basic V3' | 'Phone V2' | 'Phone V3';
}

const ApprovalThresholdForm: FC<ApprovalthresholdformProps> = ({ visible, closeHandler, data, handleRefresh, type }) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);

    // Determine which type is being edited
    const isBasicV2 = type === 'Basic V2';
    const isBasicV3 = type === 'Basic V3';
    const isPhoneV2 = type === 'Phone V2';
    const isPhoneV3 = type === 'Phone V3';

    // Set initial values based on the type
    const initialValues = {
        approvalThreshold: (() => {
            const { configJson } = data || {};
            switch (type) {
                case 'Basic V2':
                    return configJson?.thresholdCreditScoreV2;
                case 'Basic V3':
                    return configJson?.thresholdCreditScoreV3;
                case 'Phone V2':
                    return configJson?.phoneThresholdCreditScoreV2;
                case 'Phone V3':
                    return configJson?.phoneThresholdCreditScoreV3;
                default:
                    return undefined;
            }
        })(),
    };

    const { updateRiseConfig, loading } = MetaService();

    const onSubmit = (values: FormikValues) => {
        const updatedConfig = {
            configJson: {
                thresholdCreditScoreV2: isBasicV2 ? Number(values?.approvalThreshold) : data?.configJson?.thresholdCreditScoreV2,
                thresholdCreditScoreV3: isBasicV3 ? Number(values?.approvalThreshold) : data?.configJson?.thresholdCreditScoreV3,
                phoneThresholdCreditScoreV2: isPhoneV2 ? Number(values?.approvalThreshold) : data?.configJson?.phoneThresholdCreditScoreV2,
                phoneThresholdCreditScoreV3: isPhoneV3 ? Number(values?.approvalThreshold) : data?.configJson?.phoneThresholdCreditScoreV3,
            },
        };

        updateRiseConfig(updatedConfig);
        handleRefresh();
        resetFormAndClose();
    };

    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {} });
        closeHandler();
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            innerRef={formRef}
            onSubmit={onSubmit}
            validationSchema={approvalThresholdFormValidation}
        >
            {({ handleSubmit }) => (
                <ModalComponent
                    visible={visible}
                    type="small"
                    title={`Edit ${type.toLowerCase()} purchasing status approval threshold`}
                    successProps={{
                        title: "Update",
                        clickHandler: handleSubmit,
                        loading: loading,
                    }}
                    cancelProps={{
                        title: "Discard",
                        clickHandler: resetFormAndClose,
                    }}
                    closeHandler={resetFormAndClose}
                >
                    <Form>
                        <InputField placeholder="Enter" label={`${type} user approval threshold`} name="approvalThreshold" />
                    </Form>
                </ModalComponent>
            )}
        </Formik>
    );
};

export default ApprovalThresholdForm;
