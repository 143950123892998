import React, { FC, useEffect, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../shared/components/TableComponent";
import "./paymentLogs.scss";
import NoTransactionsImg from "../../../../assets/empty/transactions.svg";
import { currencyString } from "../..";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import InputField from "../../../../shared/components/InputField";
import PaymentService from "../../../../services/PaymentService/payment.service";
import {
  CustomerPaymentModel,
} from "../../../../models/Payment/payment.model";
import moment, { Moment } from "moment";
import { PaymentModeTypes } from "../../../../enums/paymentModeTypes";
import { PaymentModeTypesDefinition } from "../../../../definitions/paymentModeTypesDefinition";
import { removeUndefined } from "../../../../shared/utils/removeUndefined";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";
import { StateProps } from "../../../../models/Meta/meta.model";
import { clearFilters } from "../../../../shared/utils/clearFilters";
import { RangePickerValueType } from "../../../../shared/types/rangePicker.type";
import { generateDateString } from "../../../../shared/utils/generateDateString";
import { parseDate } from "../../../../shared/utils/formatDate";

interface PaymentLogsProps { }

const { RangePicker } = DatePicker;

export const RangePickerField = (setFieldValue: (field: string, values: unknown, shouldValidate?: boolean | undefined) => void) => (
  <RangePicker
    disabledDate={(date) => date.isAfter(moment())}
    className="picker-field"
    onChange={(_values) => {
      const data = _values?.map((i) => i?.format("DD/MM/YYYY"))
      setFieldValue("startDate", data?.[0]??null)
      setFieldValue("endDate", data?.[1]??null)
    }}
    format={'DD/MM/YYYY'} />
)

const PaymentLogs: FC<PaymentLogsProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const isPaymentlogTab = location.hash === "#payment-logs";
  const pageNumber = isPaymentlogTab ? stateValue?.pageNumber as number : null;
  let filterOptions = isPaymentlogTab ? stateValue?.filterOptions as SortFilterModel : null
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")
  const { loading, fetchCustomerPayments, customerPayments, paginations } = PaymentService();

  useEffect(() => {
    if(objectHasKeys(filters) || isPaymentlogTab){
      const updatedFilters = objectHasKeys(filters) 
      ? { page, ...filters, ...sortOptions, showTest  }
      : { page, ...sortOptions, search, ...filters, showTest  };

      fetchCustomerPayments(updatedFilters);
    }
  }, [location.hash, filters]);

  const filterInitialValues = filterOptions ?? new SortFilterModel();

  const handleFilterSubmit = (values: SortFilterModel) => {
    setPage(1)
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };
  
  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                setDateRange,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputField
                    name="fullName"
                    placeholder="Enter"
                    label="Customer Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="nationalId"
                    placeholder="Enter"
                    label="National ID"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="purchaseId"
                    placeholder="Enter"
                    label="Purchase ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>Payment Date</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          );
                          setFieldValue(
                            "startDate",
                            dataString?.split(" - ")[0]
                          );
                          setFieldValue(
                            "endDate",
                            dataString?.split(" - ")[1]
                          );
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handlePageChange = (page: number) => {
    location.state = null;
    setPage(page)
    fetchCustomerPayments({ page, ...sortOptions, ...filters, search, showTest })
  }
  return (
    <div className="payment-logs">
      <TableComponent
        onChange={() => fetchCustomerPayments({ page, ...sortOptions, ...filters, search, showTest })}
        onSort={(data) => setSortOptions(data)}
        filtersSorts={{ page, ...filters, ...sortOptions, search, showTest }}
        paginationMeta={paginations}
        pageNumber={pageNumber ?? page}
        handlePageChange={handlePageChange}
        onRow={(record)=> 
          navigate({pathname: generatePath(AppRoutes.PAYMENT_VIEW, {transactionId: record?.transactionId})}, {state: {pageNumber: page, filterOptions: filters}})
        }
        search={{
          placeholder: "Search by external payment id",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          }
        }}
        filter={filter}
        columns={[
          {
            title: "External Payment ID",
            dataIndex: "externalPaymentId",
            key: SortColumnTypes.EXTERNAL_PAYMENT_ID,
            sorter: true,
            render: (text, record) => <span>{record?.transactionType === "return" ? "-" : text}</span>
          },
          {
            title: "Customer",
            dataIndex: "customerName",
            key: "customerName",
          },
          {
            title: "Payment Date",
            dataIndex: "createdAt",
            key: SortColumnTypes.PAYMENT_DATE,
            sorter: true,
            render: (text) => moment(text)?.format("DD MMM YYYY"),
          },
          {
            title: "Payment Time",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => moment(text)?.local().format("HH:mm"),
          },
          {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            key: "paymentMode",
            render: (text: PaymentModeTypes) =>
              PaymentModeTypesDefinition[text],
          },
          {
            title: "Purchase ID",
            dataIndex: "customerPurchaseId",
            key: SortColumnTypes.PURCHASE_ID,
            sorter: true,
          },
          {
            title: "Payment Amount",
            dataIndex: "amount",
            key: SortColumnTypes.PAYMENT_AMOUNT,
            render: (text, record: CustomerPaymentModel) => (
              <span
                className={
                  record?.isAmountNegative ? "payment-loss" : "payment-profit"
                }
              >
                {record?.isAmountNegative ? "-" : "+"}&nbsp; {currencyString}
                {text}
              </span>
            ),
            sorter: true,
          },
        ]}
        data={customerPayments}
        loading={loading}
        empty={{
          text: "No payment logs",
          img: NoTransactionsImg,
        }}
      />
    </div>
  );
};

export default PaymentLogs;
