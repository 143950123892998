import React, { FC } from 'react'
import emptyStateImage from "../../../assets/images/empty-state-screen.svg"
import "./emptystatecomponent.scss"

interface EmptyStateComponentProps { 
    header: string;
    description: string;
    title: string;
    classname?: string;
}

const EmptyStateComponent: FC<EmptyStateComponentProps> = ({ header, description, title, classname }) => {

    return (
        <>
            <div className='title'>{title}</div>
            <div className={`emptystatecomponent ${classname}`}>
                <div className='container'>
                <img src={emptyStateImage} alt="data not found" />
                <div className='header'>{header}</div>
                <div className='description'>{description}</div>
                </div>
            </div>
        </>
    )
}

export default EmptyStateComponent