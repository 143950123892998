import React, { FC, Fragment, useRef, useState } from "react";
import "./customerInfoCard.scss";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import SectionCardComponent from "../../../../../../shared/components/SectionCardComponent";
import { Col, Divider, Row } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import InputField from "../../../../../../shared/components/InputField";
import { CustomerEditModel, CustomerModel } from "../../../../../../models/Customer/customer.model";
import { currencyString } from "../../../..";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { customerInfoCardValidation } from "./customerInfoCardValidation";
import { customerLateFeeValidation } from "./customerLateFeeValidation";
import { customerPurchasingPowerValdiation } from "./customerPurchasingPowerValidation";
import { formatDate } from "../../../../../../shared/utils/formatDate";
import IconComponent from "../../../../../../shared/components/IconComponent";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import { displayDash } from "../../../../../../shared/utils/displayDash";
import { DragOutlined } from '@ant-design/icons'
import { BadgeTypesDefinition } from "../../../../../../definitions/badgeTypesDefinition";
import rejectedIcon from "./../../../../../../assets/images/rejected.png"
import approvedIcon from "./../../../../../../assets/images/approved.png"
import pendingIcon from "./../../../../../../assets/images/pending.png"
import { mobileScreenWidth } from "../../../../../../enums/constants";
import moment from "moment";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import { useParams } from "react-router-dom";

interface CustomerInfoCardProps {
  customer: CustomerModel | undefined;
  isFetching: boolean;
  refreshList: () => void;
  detailsExpanded?: boolean;
  setDetailsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}
interface CustomerInfoCardFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: CustomerModel;
  loading?: boolean;
  submitHandler: (values: FormikValues) => void;
}
export const PurchasingPowerForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  submitHandler,
  loading,
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
  };
  const initialValues = {
    maxPurchasePower: data?.maxPurchasePower,
  };
  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={customerPurchasingPowerValdiation}
    >
      {({ handleSubmit }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit Purchasing Power"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField
              placeholder="Enter"
              label="Purchasing Power"
              name="maxPurchasePower"
              prefix={<span>LE</span>}
            />
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export const ProcessingFeeForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  loading,
  submitHandler,
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const initialValues = {
    processingFee: data?.processingFee,
  };
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
  };
  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={customerInfoCardValidation}
    >
      {({ handleSubmit }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit Processing Fee %"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField
              placeholder="Enter"
              label="Processing Fee"
              name="processingFee"
            />
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export const LateFeeForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  loading,
  submitHandler,
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const initialValues = {
    lateFee: data?.lateFee,
  };
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
  };

  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={customerLateFeeValidation}
    >
      {({ handleSubmit, errors }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit late fee"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField placeholder="Enter" label="Late fee" name="lateFee" />
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

const CustomerInfoCard: FC<CustomerInfoCardProps> = (props) => {
  const user = LocalStorage.getItem('USER')
  const isAdminManager = user?.role !== BadgeTypes.VIEWER;
  const isMobile = window.innerWidth <= mobileScreenWidth;
  const renderDivider = isMobile && <Divider/>
  const { isFetching, customer, refreshList, detailsExpanded, setDetailsExpanded } = props;
  const [isPurchasingPowerFormShown, setIsPurchasingPowerFormShown] =
    useState(false);
  const [isProcessingFeeFormShown, setIsProcessingFeeFormShown] =
    useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false)

  const { updateCustomerDetails, loading, resetKycRetry, loading: resetKycRetryLoading, kycVerification } = CustomerService();
  const { customerId } = useParams();

  const KYCStatus = customer?.kycStatus;
  const isRejected = customer?.status === BadgeTypes.REJECTED;
  const isApprovedUser = customer?.status === BadgeTypes.APPROVED;

  const handleUpdate = (data: CustomerEditModel) => {
    updateCustomerDetails({ ...data, id: customer?.id }, () => {
      setIsPurchasingPowerFormShown(false);
      setIsProcessingFeeFormShown(false);
      refreshList();
    });
  };

  const handleSubmit = () => customerId && resetKycRetry(customerId, true).then(()=>{ setResetModalVisible(false); refreshList() })

  return (
    <Fragment>
      <PurchasingPowerForm
        visible={isPurchasingPowerFormShown}
        data={customer}
        closeHandler={() => setIsPurchasingPowerFormShown(false)}
        loading={loading}
        submitHandler={(values) => handleUpdate(values)}
      />
      <ProcessingFeeForm
        data={customer}
        visible={isProcessingFeeFormShown}
        closeHandler={() => setIsProcessingFeeFormShown(false)}
        loading={loading}
        submitHandler={(values) => handleUpdate(values)}
      />

      <ModalComponent
        type="alert"
        className="reset-retry-count-alert"
        alert={{
          title: "Are you sure you want to reset the customer KYC verification?",
          description: "This will allow the customers to retry account verification process again through their mobile application",
          buttonType: "primary"
        }}
        visible={resetModalVisible}
        closeHandler={()=>setResetModalVisible(false)}
        successProps={{
          title: "Confirm",
          clickHandler: handleSubmit,
          loading: resetKycRetryLoading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: ()=>setResetModalVisible(false),
        }}
      />

      <SectionCardComponent
        loading={isFetching}
        className={`customer-info-card ${detailsExpanded && "max-height"}`}
        section={
          <Row className="customer-info-card-section" align="middle">
            <Col span={8}>
              <div className="deall-score">
                <p>Rise Score</p>
                <h2>{customer?.deallScore ?? 0}</h2>
              </div>
            </Col>
            <Col span={8}>
              <div className="purchasing-power">
                <p>Purchasing Power</p>
                <h2>
                  {currencyString}
                  {customer?.maxPurchasePower ?? 0}
                  {isAdminManager && customer?.status !== BadgeTypes.WAITLISTED && 
                      <IconComponent
                        onClick={() => setIsPurchasingPowerFormShown(true)}
                        name="icon-edit"
                      />
                    }
                </h2>
              </div>
            </Col>
            <Col span={8}>
              <div className="processing-fee">
                <p>Processing Fee %</p>
                <h2>
                  {customer?.processingFee ?? 0}%
                  {isAdminManager &&
                    <IconComponent
                        onClick={() => setIsProcessingFeeFormShown(true)}
                        name="icon-edit"
                      />
                  }
                </h2>
              </div>
            </Col>
          </Row>
        }
        content={
          <div className={`customer-info-card-content ${detailsExpanded && "max-height"}`}>
            {
                KYCStatus &&
                <Fragment>
                  <div className="auto-kyc">
                    <p className="title">Auto KYC</p>
                    <p className={`value kyc-value ${KYCStatus === BadgeTypes.FAILED ? "negative" : KYCStatus === BadgeTypes.KYC_PENDING ? "pending" : "positive"}`}>
                      {BadgeTypesDefinition[KYCStatus as BadgeTypes]}
                      <img
                          src={KYCStatus === BadgeTypes.FAILED ? rejectedIcon : KYCStatus === BadgeTypes.KYC_PENDING ? pendingIcon : approvedIcon}
                          className="status-icon" alt="status-icon"/>
                    </p>
                  </div>
                  <div className="verification-date">
                    <p className="title">Verification Date</p>
                    <p className="value">{displayDash(customer?.kycLastVerifiedAt)}</p>
                  </div>
                  {
                      KYCStatus === BadgeTypes.FAILED &&
                      <div className="rejected-reason">
                        <p className="title">KYC rejection reason</p>
                        <p className="value text-right">{displayDash(customer?.onboardingDetail?.digifiedComments)}</p>
                      </div>
                  }
                </Fragment>
            }
            {
              customer?.status === BadgeTypes.PENDING_APPROVAL &&
              <div className="approve-customer-third-party-details-card-item">
                <p className="title"></p>
                <p className={`value ${isMobile && "button"}`}>
                  <ButtonComponent
                    size={isMobile ? "small" : "medium"}
                    type="primary"
                    additionalTypes={"primary"}
                    className="edit-detail"
                    onClick={() => setResetModalVisible(true)}
                  >
                    Reset KYC verification
                  </ButtonComponent>
                </p>
              </div>
            }
              {customer?.allowManualKyc && <>
              <div className="approve-customer-third-party-details-card-item">
                  {!isMobile && <p className="title">Auto KYC</p>}
                  <p className={`kyc-value ${isMobile && "button"}`}>
                      <ButtonComponent
                        size={isMobile ? "small" : "medium"}
                        type="primary"
                        additionalTypes={"primary"}
                        className="edit-detail"
                        onClick={() => customer?.id && kycVerification(customer?.id?.toString()).then(() => refreshList())}
                      >
                        Manually verify KYC
                      </ButtonComponent>
                    </p>
                </div>
                {renderDivider}
                <div className="approve-customer-third-party-details-card-item">
                  <p className="title">Verification Date</p>
                  <p className="value">-</p>
                </div>
                {renderDivider}
                <Divider/>
              </>}
            {isRejected && 
            <>
              <div className="approve-customer-third-party-details-card-item">
                <p className="title">Rejected By</p>
                <p className="value">{customer?.rejectedBy?.firstName} {customer?.rejectedBy?.lastName}</p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="title">Rejected On</p>
                <p className="value">{displayDash(customer?.rejectedAt && moment(customer?.rejectedAt)?.format("DD MMM YYYY"))}</p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="title">Rejected reason</p>
                <p className="value">
                {customer?.rejectionReasons?.map((reason, index) => (
                  <Fragment key={index}>
                    {`${reason?.label}`}
                    {index !== (customer?.rejectionReasons?.length ?? 0) - 1 && ', '}
                  </Fragment>
                ))}
                </p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="title">Comment</p>
                <p className="value">{displayDash(customer?.rejectedReason)}</p>
              </div>
              {renderDivider}
            </>
          }
            <div className="national-id">
              <p className="title">National ID</p>
              <p className="value">{displayDash(customer?.nationalIdNumber)}</p>
            </div>
            <div className="full-name">
              <p className="title">Name (Customer provided name)</p>
              <p className="value">{displayDash(customer?.fullName)}</p>
            </div>
            <div className="name-from-id">
              <p className="title">Name (Captured from ID)</p>
              <p className="value">{displayDash(customer?.nameFromId)}</p>
            </div>
            <div className="dob">
              <p className="title">DOB</p>
              <p className="value">{displayDash(customer?.dateOfBirth)}</p>
            </div>
            <div className="address">
              <p className="title">Address (Captured from ID)</p>
              <p className="value">{displayDash(customer?.address)}</p>
            </div>
            <div className="address">
              <p className="title">City (Captured from ID)</p>
              <p className="value">{displayDash(customer?.city?.label)}</p>
            </div>
            <div className="address">
              <p className="title">Region (Captured from ID)</p>
              <p className="value">{displayDash(customer?.region?.label)}</p>
            </div>
            <div className="neighbourhood-address">
              <p className="title">Neighbourhood (from ID) </p>
              <p className="value">{displayDash(customer?.neighbourhood?.label)}</p>
            </div>
            <div className="questionnaire-addresss">
              <p className="title">Neighborhood (from questionnaire) </p>
              <p className="value">{displayDash(customer?.neighbourhoodFromQuestionnaire?.label)}</p>
            </div>
            <div className="job-title">
              <p className="title">Job Title</p>
              <p className="value">{displayDash(customer?.jobTitle)}</p>
            </div>
            <div className="job-place">
              <p className="title">Professional Group</p>
              <p className="value">{displayDash(customer?.professionalGroup?.label)}</p>
            </div>
            <div className="marital-status">
              <p className="title">Marital Status</p>
              <p className="value">{displayDash(customer?.maritalStatus)}</p>
            </div>
            <div className="religion">
              <p className="title">Religion</p>
              <p className="value">{displayDash(customer?.religion)}</p>
            </div>
            <div className="sex">
              <p className="title">Sex</p>
              <p className="value">{displayDash(customer?.gender)}</p>
            </div>
            <div className="iscore">
              <p className="title">iscore</p>
              <p className="value">{displayDash(customer?.iscore)}</p>
            </div>
            <div className="os">
              <p className="title">OS</p>
              <p className="value">{displayDash(customer?.os)}</p>
            </div>
            {isApprovedUser && <>
            <div className="approved-by">
              <p className="title">Approved by</p>
              <p className="value">{displayDash(customer?.onboardingDetail?.adminName)}</p>
            </div>
            <div className="approved-on">
              <p className="title">Approved on</p>
              <p className="value">
                {customer?.onboardingDetail?.onboardedAt &&
                    formatDate(customer?.onboardingDetail?.onboardedAt)}
              </p>
            </div></>}
            <div className="sex">
              <p className="title">Acquisition channel</p>
              <p className="value">{displayDash(customer?.acquisitionChannel)}</p>
            </div>
            <div className="comment">
              <p className="title">Comment</p>
              <p className="comment-value">
                {displayDash(customer?.onboardingDetail?.adminComments)}
              </p>
            </div>
            {!customer?.active && 
              <div className="comment">
                <p className="title">Deactivation reason</p>
                <p className="comment-value">
                  {displayDash(customer?.deactivationReason)}
                </p>
              </div>
            }
            <DragOutlined title="expand" className="expand-details-icon"
                          onClick={() => setDetailsExpanded((state) => !state)}/>
          </div>
        }
      />
    </Fragment>
  );
};

export default CustomerInfoCard;
